<template>
  <component-wrapper>
    <grid-container-fi
      :class="{
        'text-one-column-fi': true,
        'text-one-column-fi--centered': isCentered,
        'text-one-column-fi--dark': isDark,
      }"
    >
      <div
        :class="{
          'fi-col-start-1 fi-col-end-13 md:fi-col-start-2 md:fi-col-end-12': true,
          'lg:fi-col-start-2 lg:fi-col-end-12': isWideContent,
          'lg:fi-col-start-3 lg:fi-col-end-11': !isWideContent,
        }"
      >
        <richtext-fi
          :html-content="fields.text"
          :is-bg-dark="isDark"
        />
      </div>
    </grid-container-fi>
  </component-wrapper>
</template>

<script>
import { RichtextFi } from 'atoms';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper';
import GridContainerFi from 'components/grid-fi/GridContainerFi';
import ssrOnlyMixin from 'mixins/ssrOnlyMixin';

export default {
  name: 'TextOneColumnFi',
  components: {
    ComponentWrapper,
    RichtextFi,
    GridContainerFi,
  },
  mixins: [ssrOnlyMixin],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isCentered() {
      return Boolean(this.fields.centered?.value);
    },
    isDark() {
      return Boolean(this.fields.dark?.value);
    },
    isWideContent() {
      return Boolean(this.fields.wideContent?.value);
    },
  },
};
</script>

<style lang="scss">
@import './text-one-column-fi';
</style>
